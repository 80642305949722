import {
  Box,
  Card,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StatisticCard } from "../common/StatisticCard";
import SearchInput from "../common/Input/TextInput";
import ButtonComponent from "../common/Button";
import TableContainerComp from "../common/Table/TableContainer";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { IoPrintOutline } from "react-icons/io5";
import { SupplyData, orderDetailsColumns, orderDetailsData } from "./helper";
import CustomTableContainer from "../common/Table/CustomTableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getReservations } from "../../store/reservation/reservation.slice";
import { convertNumber } from "../../fn";

const ReservationCom = () => {
  const dispatch = useDispatch();
  const {
    reservations: { data: Reservation, total_data, totalOrderValue },
    isLoading,
  } = useSelector((state) => state.reservation);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const [tab, setTab] = React.useState("all");
  const [searchState, setSearchState] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState({
    sortType: "asc",
    sortBy: "createdAt",
  });
  const columns = orderDetailsColumns(generalInfoDetails?.dateFormat);

  const getAllReservations = (params) => {
    setSearchState(params?.search || "");
    const payload = {
      limit: params?.pageSize || pagination?.pageSize,
      page: params?.page || pagination?.page,
      sortby: params?.sortBy || sorting?.sortBy,
      sorttype: params?.sortType || sorting?.sortType,
      orderType: params?.orderType || tab,
      q: params?.search || "",
    };
    dispatch(getReservations(payload));
  };
  const changeTabHandler = (tab) => {
    setTab(tab);
    getAllReservations({ orderType: tab });
  };

  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getAllReservations({ page: page, limit: pageSize });
  };
  const sortingHandler = (sortBy) => {
    // console.log(orderByCol, sorting, "orderBy");
    const isAsc = sorting?.sortBy === sortBy && sorting?.sortType === "asc";
    setSorting({ sortType: isAsc ? "desc" : "asc", sortBy: sortBy });
    getAllReservations({ sortType: isAsc ? "desc" : "asc", sortBy: sortBy });
  };

  const searchHandler = (e) => {
    console.log(e, "value");
    getAllReservations({ page: 1, search: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  useEffect(() => {
    getAllReservations({ orderType: "all" });
  }, []);
  // console.log(reservations, "reservations");
  return (
    <>
      {/* <BreadCrumb title="Supply Management" /> */}
      <Box>
        <Box style={{ padding: "1rem 2rem" }}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={3} className="p-0">
              <StatisticCard
                title="Total Reserves"
                text={total_data || "0"}
                borderColor={"#263238"}
                titleClass={"text-start mb-7 md:text-lg xl:text-xl"}
                textClass={"font-bold md:text-2xl xl:text-3xl"}
                //   subText="6% of last month"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Total Amount"
                text={`$${convertNumber(totalOrderValue) || "0"}`}
                borderColor={"#85ABBD"}
                titleClass={"text-start mb-7 md:text-lg xl:text-xl"}
                textClass={"font-bold md:text-2xl xl:text-3xl"}
                //   subText="6% of last month"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Total Revenue"
                text={"$6,388"}
                borderColor={"#00B812"}
                titleClass={"text-start mb-7 md:text-lg xl:text-xl"}
                textClass={"font-bold md:text-2xl xl:text-3xl"}
                //   subText="6% of last month"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box className="text-start flex items-center justify-between m-2">
            <SearchInput
              placeholder="Search by text"
              wrapperClassName="me-4"
              isShowError={false}
              value={searchState}
              onChange={searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
              inputClassName="rounded-lg text-medium"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  border: 0,
                },
              }}
            />
            {/* <p className="text-lg text-zinc-400 ms-4">
                  Total surfaces :{" "}
                  <span className="text-black">{SupplyData.length}</span>
                </p> */}
            <Box className="flex items-center">
              <ButtonComponent
                btnText="Clear Filter"
                btnClass="!text-medium"
                style={{
                  padding: "7px 20px",
                  backgroundColor: "#263238",
                  "&:hover": { backgroundColor: "#263238" },
                }}
                onClick={() => {
                  searchHandler();
                }}
              />
              {/* <Divider
                  orientation="vertical"
                  style={{ height: "40px", margin: "0px 20px" }}
                />
                <Box className="flex">
                  <span className="p-1 bg-white me-3 rounded-md">
                    <FiDownload className="w-6 h-6" />
                  </span>
                  <span className="p-1 bg-white rounded-md">
                    <IoPrintOutline className="w-6 h-6" />
                  </span>
                </Box> */}
            </Box>
          </Box>
          <Box className="p-1 rounded-md bg-white mx-2">
            <Grid container>
              <Grid item xs={2} xl={1}>
                <h3
                  className="py-1 font-semibold text-medium cursor-pointer"
                  style={
                    tab === "all"
                      ? {
                          borderBottom: "2px solid #F6CA30",
                          color: "#F6CA30",
                        }
                      : {}
                  }
                  onClick={() => changeTabHandler("all")}
                >
                  All reserves{" "}
                  {/* <span
                      className="py-1 px-2 text-white rounded-full"
                      style={{ backgroundColor: "#F6CA30" }}
                    >
                      3
                    </span> */}
                </h3>
              </Grid>
              <Grid item xs={2} xl={1}>
                <h3
                  className="py-1 font-semibold text-medium cursor-pointer"
                  style={
                    tab === "active"
                      ? {
                          borderBottom: "2px solid #F6CA30",
                          color: "#F6CA30",
                        }
                      : {}
                  }
                  onClick={() => changeTabHandler("active")}
                >
                  Active{" "}
                  {/* <span
                      className="py-1 px-2 rounded-full"
                      style={{ border: "1px solid #000000" }}
                    >
                      2
                    </span> */}
                </h3>
              </Grid>
              <Grid item xs={2} xl={1}>
                <h3
                  className="py-1 font-semibold text-medium cursor-pointer"
                  style={
                    tab === "completed"
                      ? {
                          borderBottom: "2px solid #F6CA30",
                          color: "#F6CA30",
                        }
                      : {}
                  }
                  onClick={() => changeTabHandler("completed")}
                >
                  Completed{" "}
                  {/* <span
                      className="py-1 px-2 rounded-full"
                      style={{ border: "1px solid #000000" }}
                    >
                      1
                    </span> */}
                </h3>
              </Grid>
              <Grid item xs={2} xl={1}>
                <h3
                  className="py-1 font-semibold text-medium cursor-pointer"
                  style={
                    tab === "upcoming"
                      ? {
                          borderBottom: "2px solid #F6CA30",
                          color: "#F6CA30",
                        }
                      : {}
                  }
                  onClick={() => changeTabHandler("upcoming")}
                >
                  Upcoming{" "}
                  {/* <span
                      className="py-1 px-2 rounded-full"
                      style={{ border: "1px solid #000000" }}
                    >
                      0
                    </span> */}
                </h3>
              </Grid>
              <Grid item xs={1}>
                <h3
                  className="py-1 font-semibold"
                  style={
                    tab === "cancelled"
                      ? {
                          borderBottom: "2px solid #F6CA30",
                          color: "#F6CA30",
                        }
                      : {}
                  }
                  onClick={() => changeTabHandler("cancelled")}
                >
                  Cancelled
                </h3>
              </Grid>
            </Grid>
            <Divider />
            <Box className="my-3">
              <CustomTableContainer
                columns={columns}
                data={Reservation}
                isLoading={isLoading}
                rowStyle={{
                  backgroundColor: "#F7F8FA",
                  borderBottom: "7px solid white",
                  marginBottom: "5px",
                }}
                externalLink={"/reports/reservations-profile"}
                // style={{ maxHeight: "30vh", overflowY: "scroll" }}
                pagination={true}
                paginationDetails={{
                  ...pagination,
                  total_data,
                  pageHandler: pageHandler,
                }}
                sortingDetails={sorting}
                sortingHandler={sortingHandler}
              />
              {/* <TableContainerComp
                  columns={columns}
                  rows={AccountData}
                  pagination={true}
                  hasLink={true}
                  navigateURL={"/account-profile"}
                /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReservationCom;
