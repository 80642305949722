import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import ButtonComponent from "../common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FoodBar } from "../../assets/images/FoodBar.svg";
import SearchInput from "../common/Input/TextInput";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { IoPrintOutline } from "react-icons/io5";
import { SupplyData, generateColumns } from "./helper";
import TableContainerComp from "../common/Table/TableContainer";
import { getSupplies } from "../../store/supply/supply.slice";
import { useDispatch, useSelector } from "react-redux";

export const BusinessWiseProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const columns = generateColumns(generalInfoDetails?.dateFormat);
  const {
    supplies: { data: Supplies, total_data },
    isLoading,
  } = useSelector((state) => state.supply);
  const stores = [
    {
      img: null,
      storeName: "K Mart",
      place: "New York",
    },
    {
      img: null,
      storeName: "E Mart",
      place: "Manhattan",
    },
    {
      img: null,
      storeName: "H Mart",
      place: "Manhattan",
    },
    {
      img: null,
      storeName: "E Mart",
      place: "Manhattan",
    },
  ];
  const [reviewFilter, setReviewFilter] = useState("1 month");
  const [expanded, setExpanded] = useState(false);
  const [redFlag, setRedFlag] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [searchState, setSearchState] = useState("");
  const [sorting, setSorting] = useState({
    order: "asc",
    orderBy: "createdAt",
  });

  const getSupplyList = (details) => {
    dispatch(
      getSupplies({
        page: details?.page || pagination.page,
        limit: details?.pageSize || pagination.pageSize,
        q: details?.q || "",
        sortby: details?.orderBy || sorting.orderBy,
        sorttype: details?.order || sorting.order,
        bussinessId: details?.bussinessId || location?.state?._id,
      })
    );
  };

  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getSupplyList({
      page,
      pageSize,
    });
  };

  const searchHandler = (e) => {
    console.log(e, "value");
    getSupplyList({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  const sortingHandler = (orderByCol) => {
    console.log(orderByCol, sorting, "orderBy");
    const isAsc = sorting?.orderBy === orderByCol && sorting?.order === "asc";
    setSorting({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
    getSupplyList({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
  };

  useEffect(() => {
    getSupplyList();
  }, [location?.state]);
  return (
    <Box>
      <BreadCrumb
        title="Business Details"
        parents={["Supply Management"]}
        parentsURL={["/supply"]}
      />
      <Box className="mx-2">
        <Grid container>
          <Grid item xs={6}>
            <Card>
              <Grid container spacing={2} className="text-start p-4 pb-0">
                <Grid item xs={5}>
                  <Box>
                    <h1 className="text-xl font-semibold">Business Details</h1>
                    <Box className="flex p-3 items-center">
                      <img
                        className="rounded-lg"
                        src={location?.state?.profileUrl || "/images/store.png"}
                        alt=""
                        style={{ width: "60px", height: "60px" }}
                      />
                      <Box className="ms-2">
                        <Box className="flex items-center">
                          <h3 className="text-zinc-700 me-2">
                            {location?.state?.name || " "}
                          </h3>
                          <span
                            className="inline-block p-2 rounded-full"
                            style={{ backgroundColor: "#FCF0C6" }}
                          >
                            <FoodBar className="w-5 h-5" />
                          </span>
                        </Box>
                        <p className="text-zinc-400 text-sm">
                          {location?.state?.street}, {location?.state?.state},
                        </p>
                        <p className="text-zinc-400 text-sm">
                          {location?.state?.country}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  style={{ height: "150px" }}
                />
                <Grid item xs={6}>
                  <Box>
                    <h1 className="text-xl font-semibold">Partners</h1>
                    <Box className="flex p-3 text-start items-center">
                      <Box className="flex flex-col items-center me-3">
                        <img
                          src="/images/dummy-user.jpg"
                          alt=""
                          style={{ height: "60px", width: "60px" }}
                        />
                        <p className="text-sm whitespace-nowrap">Tony Stark</p>
                        <p className="text-zinc-400 text-sm">Owner</p>
                      </Box>
                      {location?.state?.businessPartners?.map((partner) => {
                        return (
                          <Box className="flex flex-col items-center me-3">
                            <img
                              src={
                                partner?.profileUrl || "/images/dummy-user.jpg"
                              }
                              alt=""
                              className="partner-profile-small-img"
                            />
                            <p className="md:text-xs xl:text-sm whitespace-nowrap">
                              {`${partner?.fname} ${partner?.lname}`}
                            </p>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box className="flex items-center justify-between my-3 w-full">
                <Box>
                  <SearchInput
                    placeholder="Search by text"
                    wrapperClassName="me-4"
                    isShowError={false}
                    value={searchState}
                    onChange={searchHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton>
                          <CiSearch />
                        </IconButton>
                      </InputAdornment>
                    }
                    inputClassName="rounded-lg"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        border: 0,
                      },
                    }}
                  />
                </Box>
                <Box className="flex items-center">
                  <ButtonComponent
                    btnText="Clear Filter"
                    style={{
                      padding: "7px 20px",
                      backgroundColor: "#263238",
                      "&:hover": { backgroundColor: "#263238" },
                    }}
                    onClick={() => {
                      searchHandler();
                    }}
                  />
                  <p className="text-lg text-zinc-400 ms-4">
                    Total surfaces :{" "}
                    <span className="text-black">{total_data}</span>
                  </p>
                </Box>
              </Box>
              <Box className="mt-4">
                <TableContainerComp
                  columns={columns}
                  rows={Supplies}
                  pagination={true}
                  hasLink={true}
                  isLoading={isLoading}
                  sortingDetails={sorting}
                  sortingHandler={sortingHandler}
                  navigateURL={"/supply/supplywise-profile"}
                  paginationDetails={{
                    ...pagination,
                    total_data,
                    pageHandler: pageHandler,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
