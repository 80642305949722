import React, { useEffect, useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import { Box, Card, Divider, Grid } from "@mui/material";
import { StatisticCard } from "../common/StatisticCard";
import { GoDotFill } from "react-icons/go";
import { RiPhoneFill } from "react-icons/ri";
import { IoIosMail } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { ReactComponent as FoodBar } from "../../assets/images/FoodBar.svg";
import ButtonComponent from "../common/Button";
import ChatRoom from "./ChatRoom";
import { useDispatch, useSelector } from "react-redux";
import { getReservationDetails } from "../../store/reservation/reservation.slice";
import { useLocation } from "react-router-dom";
import moment from "moment";

const ReservationProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { reservationDetails } = useSelector((state) => state.reservation);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const [toggleChatRoom, setToggleChatRoom] = useState(false);
  const [reservationsData, setReservationsData] = useState({});
  console.log(reservationDetails.timeline, "reservationDetails");
  useEffect(() => {
    dispatch(getReservationDetails({ id: location.state._id }));
  }, [location.state]);
  useEffect(() => {
    const reservations = {};
    reservationDetails.timeline?.map((timeline) => {
      const oldData = reservations[new Date(timeline?.tDate).toDateString()];
      if (oldData) {
        reservations[new Date(timeline?.tDate).toDateString()] = [
          ...oldData,
          timeline,
        ];
      } else {
        reservations[new Date(timeline?.tDate).toDateString()] = [timeline];
      }
    });
    setReservationsData(reservations);
  }, [reservationDetails.timeline]);
  console.log(reservationsData, "reservationsData");
  return (
    <Box className="reservation-profile">
      <BreadCrumb
        title="Details"
        parents={["Reports"]}
        parentsURL={["/reports"]}
      />
      <Box
        className="mx-2"
        sx={{
          maxHeight: `calc(100vh - 102px - 1.5rem)`,
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} className="p-4">
          <Grid item xs={12} xl={7}>
            <Box>
              <Box className="mb-5">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <StatisticCard
                      title="Order #"
                      text={reservationDetails?.orderId}
                      hasSubText={false}
                      titleClass={
                        "text-zinc-400 font-bold text-start mb-10 text-medium"
                      }
                      textClass={"font-bold md:text-xl xl:text-2xl"}
                      // subText="6% of last month"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatisticCard
                      title="Order Date"
                      text={moment(reservationDetails?.fromDate).format(
                        generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                      )}
                      hasSubText={false}
                      titleClass={
                        "text-zinc-400 font-bold text-start mb-10 text-medium"
                      }
                      textClass={"font-bold md:text-xl xl:text-2xl"}
                      // subText="6% of last month"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatisticCard
                      title="Status"
                      text={
                        <>
                          <span>
                            <GoDotFill color="green" />
                          </span>
                          Active
                        </>
                      }
                      hasSubText={false}
                      titleClass={
                        "text-md text-zinc-400 font-bold text-start mb-10 text-medium"
                      }
                      textClass={
                        "font-bold flex items-center md:text-xl xl:text-2xl"
                      }
                      // subText="6% of last month"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      className="p-4"
                      sx={{
                        borderRadius: "10px",
                        width: "320px",
                        maxWidth: "100%",
                      }}
                    >
                      <h3
                        className={
                          "text-medium text-zinc-400 font-bold text-start"
                        }
                      >
                        Order Period
                      </h3>
                      <Box className="block">
                        <p className="font-semibold m-0 md:text-md xl:text-lg">
                          {moment(reservationDetails?.fromDate).format(
                            generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                          )}
                        </p>
                        <p className="m-0 text-center text-medium">to</p>
                        <p className="font-semibold m-0 md:text-md xl:text-lg">
                          {moment(reservationDetails?.toDate).format(
                            generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                          )}
                        </p>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box className="mb-5">
                <Card className="p-5">
                  <Grid container columnSpacing={3}>
                    <Grid item xs={4}>
                      <Box className="flex flex-col justify-center me-4">
                        <h1 className="font-semibold text-start mb-3 md:text-lg xl:text-xl">
                          Surface
                        </h1>
                        <Box className="flex">
                          <img
                            className="rounded-lg reservation-img"
                            src={
                              reservationDetails?.surface?.businessProfile
                                ?.profileUrl || "/images/store.png"
                            }
                            alt=""
                            // style={{ width: "70px", height: "70px" }}
                          />
                          <Box className="m-2 text-start">
                            <h3 className="text-zinc-700 text-medium">
                              {reservationDetails?.surface?.businessProfile
                                ?.name || ""}
                            </h3>
                            <Box className="flex text-zinc-700">
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {reservationDetails?.surface?.category
                                  ?.categoryName || ""}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={4}>
                      <Box className="flex flex-col justify-center me-4">
                        <h1 className="md:text-lg xl:text-xl font-semibold text-start mb-3">
                          Supply
                        </h1>
                        <Box className="flex">
                          <img
                            className="rounded-lg reservation-img"
                            src={
                              reservationDetails?.supplyUser?.businesses[0]
                                ?.profileUrl || "/images/BiggerCup.png"
                            }
                            alt=""
                            // style={{ width: "70px", height: "70px" }}
                          />
                          <Box className="m-2 text-start">
                            <h3 className="text-zinc-700 text-medium">
                              {reservationDetails?.supplyUser?.businesses[0]
                                ?.name || ""}
                            </h3>
                            <Box className="flex text-zinc-700">
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {reservationDetails?.supplyUser?.businesses[0]
                                  ?.category[0]?.categoryName || ""}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={3}>
                      <Box className="flex flex-col justify-center me-4">
                        <h1 className="md:text-lg xl:text-xl font-semibold text-start mb-3">
                          Order Qty
                        </h1>
                        <Box className="flex">
                          <p className="md:text-2xl xl:text-3xl font-bold">
                            {reservationDetails?.qty}
                          </p>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
              <Box>
                <Card className="p-5">
                  <Grid container columnSpacing={3}>
                    <Grid item xs={6}>
                      <Box className="text-start">
                        <Box className="mb-5">
                          <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                            Contact
                          </h1>
                          <Box>
                            <p className="flex items-center mb-2 text-medium">
                              <span className="me-2">
                                <RiPhoneFill className="md:w-5 md:h-5 xl:w-6 xl:h-6" />
                              </span>
                              {`${reservationDetails?.user?.countryCode}${reservationDetails?.user?.phone}`}
                            </p>
                            <p className="flex items-center text-medium">
                              <span className="me-2">
                                <IoIosMail className="md:w-6 md:h-6 xl:w-7 xl:h-7" />
                              </span>
                              {reservationDetails?.user?.email}
                            </p>
                          </Box>
                        </Box>
                        <Box className="flex justify-between">
                          <Box>
                            <h1 className="md:text-lg xl:text-xl font-semibold mb-2">
                              Billing Address
                            </h1>
                            <Box className="text-medium">
                              <p>{reservationDetails?.user?.address},</p>
                              {/* <p>1095 6th Ave,</p> */}
                              <p>{reservationDetails?.user?.state}, </p>
                              <p>{reservationDetails?.user?.zipCode}, </p>
                              <p>{reservationDetails?.user?.country}</p>
                            </Box>
                          </Box>
                          <Divider orientation="vertical" flexItem />
                          <Box className="ms-2">
                            <h1 className="md:text-lg xl:text-xl font-semibold mb-2">
                              Shipping Address
                            </h1>
                            <Box className="text-medium">
                              <p>Coffee Shop,</p>
                              <p>1095 6th Ave,</p>
                              <p>New York, </p>
                              <p>NY 10018, </p>
                              <p>United States of America</p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={5}>
                      <Box className="text-start flex flex-col items-stretch">
                        <Box className="mb-8">
                          <h1 className="md:text-lg xl:text-xl font-semibold md:mb-1 xl:mb-3">
                            Payment method
                          </h1>
                          <p className="text-medium">
                            {reservationDetails?.paymentDetails?.paymentMethod}
                          </p>
                        </Box>
                        <Box>
                          <h1 className="md:text-lg xl:text-xl font-semibold md:mb-1 xl:mb-3">
                            Order Summary
                          </h1>
                          <Box>
                            <Box className="flex justify-between md:mb-1 xl:mb-2 text-medium">
                              <p>Surface Total</p>
                              <p>
                                $
                                {reservationDetails?.paymentDetails?.grossTotal}
                              </p>
                            </Box>
                            <Box className="flex justify-between md:mb-1 xl:mb-2 text-medium">
                              <p>Sharer Charge</p>
                              <p>
                                $
                                {
                                  reservationDetails?.paymentDetails
                                    ?.sharerTotal
                                }
                              </p>
                            </Box>
                            <Box className="flex justify-between md:mb-1 xl:mb-2 text-medium">
                              <p>Service Fee</p>
                              <p>
                                $
                                {
                                  reservationDetails?.paymentDetails
                                    ?.serviceCharge
                                }
                              </p>
                            </Box>
                            <Box className="flex justify-between md:mb-1 xl:mb-2 text-medium">
                              <p>Delivery</p>
                              <p>$5.00</p>
                            </Box>
                            <Box className="flex justify-between md:mb-1 xl:mb-2 md:!text-md xl:!text-lg font-semibold">
                              <p>Order Total</p>
                              <p>
                                $
                                {reservationDetails?.paymentDetails?.orderTotal}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} xl={5}>
            <Grid container spacing={2}>
              <Grid item xs={6} xl={12}>
                <Card style={{ height: "100%" }}>
                  <Box
                    style={{ height: "100%" }}
                    className="flex flex-col justify-between"
                  >
                    <Box className="p-4">
                      <Box className="text-start">
                        <Box className="flex justify-between">
                          <h1 className="md:text-lg xl:text-xl font-semibold md:mb-2 xl:mb-3">
                            Seeker
                          </h1>
                          <h1 className="md:text-lg xl:text-xl font-semibold md:mb-2 xl:mb-3">
                            History
                          </h1>
                        </Box>
                        <Box className="text-start flex justify-between">
                          <Box>
                            <Box className="flex items-center">
                              <img
                                src={
                                  reservationDetails?.user?.profileUrl ||
                                  "/images/dummy-user.jpg"
                                }
                                alt=""
                                className="reservation-small-img"
                                // style={{ width: "60px", height: "60px" }}
                              />
                              <Box className="ms-2">
                                <h3 className="text-zinc-700 font-semibold text-medium">
                                  {`${reservationDetails?.user?.fname} ${reservationDetails?.user?.lname}`}
                                </h3>
                                <Box className="flex items-center text-zinc-700 text-medium">
                                  <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />
                                  <h1>
                                    <span>
                                      {reservationDetails?.user?.avgRating?.toFixed(
                                        2
                                      )}
                                    </span>
                                  </h1>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="flex items-center">
                            <Box className="me-2 text-end">
                              <h3 className="text-medium text-zinc-700 flex justify-end items-center">
                                {reservationDetails?.user?.businesses[0]?.name}
                                <span
                                  className="p-1 ms-2 rounded-full"
                                  style={{ backgroundColor: "#FCF0C6" }}
                                >
                                  <FoodBar
                                    id="food"
                                    className="md:w-4 md:h-4 xl:w-5 xl:h-5"
                                  />
                                </span>
                              </h3>
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {`${reservationDetails?.user?.businesses[0]?.street},${reservationDetails?.user?.businesses[0]?.state}`}
                              </p>
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {
                                  reservationDetails?.user?.businesses[0]
                                    ?.country
                                }
                              </p>
                            </Box>
                            <img
                              className="rounded-lg reservation-small-img"
                              src={
                                reservationDetails?.user?.businesses[0]
                                  ?.profileUrl || "/images/store.png"
                              }
                              alt=""
                              // style={{ width: "54px", height: "54px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="text-start">
                        <h1 className="md:text-lg xl:text-xl font-semibold md:mb-2 xl:mb-3">
                          Sharer
                        </h1>
                        <Box className="text-start flex justify-between">
                          <Box>
                            <Box className="flex items-center">
                              <img
                                src={
                                  reservationDetails?.sharerUser?.profileUrl ||
                                  "/images/dummy-user.jpg"
                                }
                                alt=""
                                className="reservation-small-img"
                                // style={{ width: "60px", height: "60px" }}
                              />
                              <Box className="ms-2">
                                <h3 className="text-zinc-700 font-semibold text-medium">
                                  {`${reservationDetails?.sharerUser?.fname} ${reservationDetails?.sharerUser?.lname}`}
                                </h3>
                                <Box className="flex items-center text-zinc-700 text-medium">
                                  <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />
                                  <h1>
                                    <span>
                                      {reservationDetails?.sharerUser?.avgRating?.toFixed(
                                        2
                                      )}
                                    </span>
                                  </h1>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="flex items-center">
                            <Box className="me-2 text-end">
                              <h3 className="text-medium text-zinc-700 flex justify-end items-center">
                                {
                                  reservationDetails?.sharerUser?.businesses[0]
                                    ?.name
                                }
                                <span
                                  className="p-1 ms-2 rounded-full"
                                  style={{ backgroundColor: "#FCF0C6" }}
                                >
                                  <FoodBar
                                    id="food"
                                    className="md:w-4 md:h-4 xl:w-5 xl:h-5"
                                  />
                                </span>
                              </h3>
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {`${reservationDetails?.sharerUser?.businesses[0]?.street},${reservationDetails?.sharerUser?.businesses[0]?.state}`}
                              </p>
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {
                                  reservationDetails?.sharerUser?.businesses[0]
                                    ?.country
                                }
                              </p>
                            </Box>
                            <img
                              className="rounded-lg reservation-small-img"
                              src={
                                reservationDetails?.sharerUser?.businesses[0]
                                  ?.profileUrl || "/images/store.png"
                              }
                              alt=""
                              // style={{ width: "54px", height: "54px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="text-start">
                        <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                          Supplier
                        </h1>
                        <Box className="text-start flex justify-between">
                          <Box>
                            <Box className="flex items-center">
                              <img
                                src={
                                  reservationDetails?.supplyUser?.profileUrl ||
                                  "/images/dummy-user.jpg"
                                }
                                alt=""
                                className="reservation-small-img"
                                // style={{ width: "60px", height: "60px" }}
                              />
                              <Box className="ms-2">
                                <h3 className="text-zinc-700 font-semibold text-medium">
                                  {`${reservationDetails?.supplyUser?.fname} ${reservationDetails?.supplyUser?.lname}`}
                                </h3>
                                <Box className="flex items-center text-zinc-700 text-medium">
                                  <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-2" />
                                  <h1>
                                    <span>
                                      {reservationDetails?.supplyUser?.avgRating?.toFixed(
                                        2
                                      )}
                                    </span>
                                  </h1>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="flex items-center">
                            <Box className="me-2 text-end">
                              <h3 className="text-medium text-zinc-700 flex justify-end items-center">
                                {
                                  reservationDetails?.supplyUser?.businesses[0]
                                    ?.name
                                }
                                <span
                                  className="p-1 ms-2 rounded-full"
                                  style={{ backgroundColor: "#FCF0C6" }}
                                >
                                  <FoodBar
                                    id="food"
                                    className="md:w-4 md:h-4 xl:w-5 xl:h-5"
                                  />
                                </span>
                              </h3>
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {`${reservationDetails?.supplyUser?.businesses[0]?.street},${reservationDetails?.supplyUser?.businesses[0]?.state}`}
                              </p>
                              <p className="md:text-xs xl:text-sm text-zinc-400">
                                {
                                  reservationDetails?.supplyUser?.businesses[0]
                                    ?.country
                                }
                              </p>
                            </Box>
                            <img
                              className="rounded-lg reservation-small-img"
                              src="/images/store.png"
                              alt=""
                              // style={{ width: "54px", height: "54px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className="w-full p-3 rounded-md"
                      style={{ backgroundColor: "#F9DD7B" }}
                    >
                      <h1
                        className="md:text-lg xl:text-xl font-semibold cursor-pointer"
                        onClick={() => setToggleChatRoom((pre) => !pre)}
                      >
                        Chat Room
                      </h1>
                      {toggleChatRoom && (
                        <Box
                          className="w-90 mt-5 rounded-lg overflow-y-scroll"
                          style={{
                            height: "350px",
                            backgroundColor: "#FCF0C6",
                          }}
                        >
                          <ChatRoom />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6} xl={12}>
                <Card>
                  <Box className="pt-4">
                    <Box className="text-start px-4">
                      <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                        Surface Timeline
                      </h1>
                    </Box>
                    <Box
                      className="flex w-full overflow-x-scroll"
                      style={{
                        boxShadow:
                          "0px 2px 10px #D2D2D2, inset 2px -5px 10px #D2D2D2",
                      }}
                    >
                      {Object.keys(reservationsData)?.map((time) => {
                        const records = reservationsData[time];
                        return (
                          <Box
                            className="p-2"
                            style={{
                              width: "8rem",
                              height: "20rem",
                              border: "1px solid #D2D2D2",
                            }}
                          >
                            <Box className="flex mb-5">
                              <p className="md:text-4xl xl:text-5xl font-semibold me-1 text-zinc-700">
                                {new Date(time).getDate()}
                              </p>
                              <Box className="text-zinc-700 text-medium">
                                <p>
                                  {new Date(time).toLocaleString("default", {
                                    month: "short",
                                  })}
                                </p>
                                <p>
                                  {new Date(time).toLocaleString("default", {
                                    weekday: "short",
                                  })}
                                </p>
                              </Box>
                            </Box>
                            <Box className="text-start">
                              <ul>
                                {records?.map((record) => {
                                  return (
                                    <li className="text-medium mb-2">
                                      {record.description}
                                    </li>
                                  );
                                })}
                              </ul>
                              {/* <h3 className="text-medium">
                                {time?.description}
                              </h3> */}
                              {/* <p className="md:text-xs xl:text-sm text-zinc-500">
                                {time?.description}
                              </p> */}
                            </Box>
                          </Box>
                        );
                      })}
                      {/* 
                      <Box
                        className="p-2"
                        style={{
                          width: "8rem",
                          height: "20rem",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <Box className="flex mb-5">
                          <p className="md:text-4xl xl:text-5xl font-semibold me-1 text-zinc-700">
                            11
                          </p>
                          <Box className="text-zinc-700 text-medium">
                            <p>May</p>
                            <p>Sun</p>
                          </Box>
                        </Box>
                        <Box className="text-start">
                          <h3 className="text-medium">Surface Received</h3>
                          <p className="md:text-xs xl:text-sm text-zinc-500">
                            Surface received Succefully
                          </p>
                        </Box>
                      </Box>
                      <Box
                        className="p-2"
                        style={{
                          width: "8rem",
                          height: "20rem",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <Box className="flex mb-5">
                          <p className="md:text-4xl xl:text-5xl font-semibold me-1 text-zinc-700">
                            15
                          </p>
                          <Box className="text-zinc-700 text-medium">
                            <p>May</p>
                            <p>Sun</p>
                          </Box>
                        </Box>
                        <Box className="text-start">
                          <h3 className="text-medium">
                            Package send By Supplier
                          </h3>
                        </Box>
                      </Box>
                      <Box
                        className="p-2"
                        style={{
                          width: "8rem",
                          height: "20rem",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <Box className="flex mb-5">
                          <p className="md:text-4xl xl:text-5xl font-semibold me-1 text-zinc-700">
                            18
                          </p>
                          <Box className="text-zinc-700 text-medium">
                            <p>May</p>
                            <p>Sun</p>
                          </Box>
                        </Box>
                        <Box className="text-start">
                          <h3 className="text-medium">
                            Package received By Sharer
                          </h3>
                        </Box>
                      </Box>
                      <Box
                        className="p-2"
                        style={{
                          width: "8rem",
                          height: "20rem",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <Box className="flex mb-5">
                          <p className="md:text-4xl xl:text-5xl font-semibold me-1 text-zinc-700">
                            20
                          </p>
                          <Box className="text-zinc-700 text-medium">
                            <p>May</p>
                            <p>Thu</p>
                          </Box>
                        </Box>
                        <Box className="text-start text-medium">
                          <h3>Day 1</h3>
                          <h3>Surface Period begin</h3>
                        </Box>
                      </Box>
                      <Box
                        className="p-2"
                        style={{
                          width: "8rem",
                          height: "20rem",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <Box className="flex mb-5">
                          <p className="md:text-4xl xl:text-5xl font-semibold me-1 text-zinc-700">
                            21
                          </p>
                          <Box className="text-zinc-700 text-medium">
                            <p>May</p>
                            <p>Sun</p>
                          </Box>
                        </Box>
                        <Box className="text-start text-medium">
                          <h3>Day2</h3>
                        </Box>
                      </Box> */}
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReservationProfile;
